import React, { Component } from 'react';
import fullprogramimage from './cover.png';
import booklogoimage from './booklogo.jpg';
import './App.css';
import Button from './Button';
import Header from './Header';

class App extends Component {
    render() {
        const fullButtonStyle = {
            width: '100%',
            paddingTop: '5vh',
            height: '90vh',
        };

        const containerStyle = {
            margin: 0,
            padding: 0,
        };

        const paragraphStyle = {
            fontSize: 20
        };

        return (
            <div style={containerStyle}>
                <Header />
                <div className="App">
                    <div className="halfWidth">
                        <Button href="https://www.udemy.com/course/the-7-rules-to-success/?referralCode=E3FE789AB429B4A29691">
                            <div style={fullButtonStyle}>
                                <img src={fullprogramimage} className="App-logo" alt="7 rules success program" />
                                <p style={paragraphStyle}>
                                    The full program with over 14 hours of video and the action steps handbook. -50% OFF with this link:
                                </p>
                                <div className="myButton">
                                    Get The Videos
                                </div>
                            </div>
                        </Button>
                    </div>
                    <div className="halfWidth">
                        <Button href="https://amzn.to/3azJRzR">
                            <div style={fullButtonStyle}>
                                <img src={booklogoimage} className="App-logo" alt="7 rules to success book" />
                                <p style={paragraphStyle}>
                                    The book available on Kindle and in print!
                                </p>
                                <div className="myButton">
                                    Get The Book
                                </div>
                            </div>
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}

export default App;
