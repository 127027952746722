import React, { Component } from 'react';

export default class Button extends Component {
    render() {
        const linkStyle = {
            width: '100%',
            color: 'black',
            textDecoration: 'none',
        };

        return (
          <a style={linkStyle} href={this.props.href} target="_blank" rel="noopener noreferrer">
              {this.props.children}
          </a>
        );
    }
}