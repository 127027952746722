import React, { Component } from 'react';

export default class Header extends Component {
    render() {
        const containerStyle = {
            width: '100%',
            boxShadow: '1px 1px 1px black',
            textAlign: 'center',
            backgroundColor: '#000042',
            color: 'white',
            marginTop: '-20px',
            paddingTop: '20px',
            paddingBottom: '10px',
        };

        const linkStyle = {
            color: 'white',
        };

        return (
            <div style={containerStyle}>
                <h1>
                    The 7 Rules to Success
                </h1>
                <p>
                    Click on the links below to access the programs. You can also email me with questions - Alex Ogorek
                </p>
                <a style={linkStyle} href="mailto:alex@alexogorek.com">
                    alex@alexogorek.com
                </a>
            </div>
        );
    }
}